import { reactive, defineComponent, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import DIContainer from "@/core/DIContainer";
import MeetingEditPopup from "@/presentation/components/modals/meeting-edit/MeetingEdit.vue";
import AchievementEditPopup from "@/presentation/components/modals/achievement-edit/AchievementEdit.vue";
import AchievementEditDifferenceDisplayPopup from "@/presentation/components/modals/achievement-edit-different-display/AchievementEditDifferenceDisplay.vue";
import RatingEditPopup from "@/presentation/components/modals/rating-edit/RatingEdit.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import RoutePath from "@/commons/RoutePath";
import CommonService from "@/services/CommonService";
import router from "@/presentation/router";
import QuestionnaireInteractor from "@/domain/usecases/QuestionnaireInteractor";
import MilestoneInteractor from "@/domain/usecases/MilestoneInteractor";
import DeliverableInteractor from "@/domain/usecases/DeliverableInteractor";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import MeetingEditDifferenceDisplayPopup from "@/presentation/components/modals/meeting-edit-difference-display/MeetingEditDifferenceDisplay.vue";
export var ModalType;
(function (ModalType) {
    ModalType["Kickoff"] = "Kickoff";
    ModalType["KickoffEdit"] = "KickoffEdit";
    ModalType["Meeting"] = "Meeting";
    ModalType["MeetingAdd"] = "MeetingAdd";
    ModalType["MeetingLast"] = "MeetingLast";
    ModalType["MeetingEdit"] = "MeetingEdit";
    ModalType["Achievement"] = "Achievement";
    ModalType["AchievementEdit"] = "AchievementEdit";
    ModalType["Rating"] = "Rating";
})(ModalType || (ModalType = {}));
export var MileStoneStep;
(function (MileStoneStep) {
    MileStoneStep[MileStoneStep["init"] = 1] = "init";
    MileStoneStep[MileStoneStep["kickoff"] = 2] = "kickoff";
    MileStoneStep[MileStoneStep["meeting"] = 3] = "meeting";
    MileStoneStep[MileStoneStep["review"] = 4] = "review";
    MileStoneStep[MileStoneStep["confirmed"] = 5] = "confirmed";
})(MileStoneStep || (MileStoneStep = {}));
export default defineComponent({
    name: "ProjectDetailManagement",
    components: {
        Preloaders: Preloaders,
        MeetingEditPopup: MeetingEditPopup,
        AchievementEditPopup: AchievementEditPopup,
        AchievementEditDifferenceDisplayPopup: AchievementEditDifferenceDisplayPopup,
        RatingEditPopup: RatingEditPopup,
        Alert: Alert,
        MeetingEditDifferenceDisplayPopup: MeetingEditDifferenceDisplayPopup,
    },
    setup: function () {
        var store = useStore();
        var route = useRoute();
        var state = reactive({
            projectId: Number(route.params.id),
            teamId: Number(route.params.team_id),
            preload: true,
            modalType: null,
            step: MileStoneStep.meeting,
            milestoneSource: [],
            questionnaire: [],
            kickOff: null,
            regulars: [],
            review: null,
            selectedMilestone: null,
            achievement: null,
            editAchievement: null,
            ratingDate: "",
            copiedMilestoneIdForUser: null,
            copiedMilestoneIdForOrg: null,
            copiedAchievementIdForUser: null,
            copiedAchievementIdForOrg: null,
            editForm: null,
            latestForm: null,
            latestQuestionnaire: null,
        });
        var kickoffQuestionnaireInteractor = DIContainer.instance.get(QuestionnaireInteractor);
        var isStepFinishedReview = function (review) {
            return review !== null && !!review.note;
        };
        var setMilestonesToDisplayable = function (milestones) {
            var kickoff = [];
            var regular = [];
            var review = [];
            for (var _i = 0, milestones_1 = milestones; _i < milestones_1.length; _i++) {
                var milestone = milestones_1[_i];
                switch (milestone.meeting_type) {
                    case "KICKOFF": {
                        kickoff.push(milestone);
                        break;
                    }
                    case "REGULAR": {
                        regular.push(milestone);
                        break;
                    }
                    case "REVIEW": {
                        review.push(milestone);
                        break;
                    }
                }
            }
            state.kickOff = kickoff.length > 0 ? kickoff[0] : null;
            state.regulars = regular;
            state.review = review.length > 0 ? review[0] : null;
            if (state.review && state.step <= MileStoneStep.review && isStepFinishedReview(state.review)) {
                state.step = MileStoneStep.review;
            }
        };
        function updateMilestone(milestone) {
            if (state.selectedMilestone && state.selectedMilestone.id === milestone.id) {
                state.selectedMilestone = milestone;
            }
            var milestoneIndex = state.milestoneSource.findIndex(function (source) { return source.id === milestone.id; });
            if (milestoneIndex < 0) {
                state.milestoneSource.push(milestone);
            }
            else {
                state.milestoneSource[milestoneIndex] = milestone;
            }
            state.milestoneSource.sort(function (a, b) { return a.meeting_start_at < b.meeting_start_at ? -1 : 1; });
            setMilestonesToDisplayable(state.milestoneSource);
        }
        function updateEditMilestone(milestoneform) {
            state.editForm = milestoneform;
            if (milestoneform.meeting_type === "KICKOFF") {
                state.modalType = ModalType.KickoffEdit;
            }
            else {
                state.modalType = ModalType.MeetingEdit;
            }
        }
        function updateLatestMilestone(milestoneform) {
            state.latestForm = milestoneform;
        }
        function updateLatestQuestionnaire(questionnaireForm) {
            state.latestQuestionnaire = questionnaireForm;
        }
        function updateAchievement(achievement) {
            state.achievement = achievement;
        }
        function closeEditDifferenceModal() {
            state.modalType = null;
            state.editForm = null;
            state.latestForm = null;
        }
        function updateEditAchievement(achievement) {
            state.modalType = ModalType.AchievementEdit;
            state.editAchievement = achievement;
        }
        function todayStr() {
            var now = new Date();
            return now.getFullYear() + "/" + (now.getMonth() + 1) + "/" + now.getDate();
        }
        function copyUrlToClipboard(typeFor, id) {
            var url;
            switch (typeFor) {
                case "USER_MILESTONE":
                    url = basePathForUser.value + "?milestone=" + id;
                    break;
                case "ORG_MILESTONE":
                    url = basePathForOrganization.value + "?milestone=" + id;
                    break;
                case "USER_ACHIEVEMENT":
                    url = basePathForUser.value + "?achievement=" + id;
                    break;
                case "ORG_ACHIEVEMENT":
                    url = basePathForOrganization.value + "?achievement=" + id;
                    break;
            }
            navigator.clipboard.writeText(url).then(function () {
                state.copiedMilestoneIdForUser
                    = state.copiedMilestoneIdForOrg
                        = state.copiedAchievementIdForUser
                            = state.copiedAchievementIdForOrg
                                = null;
                switch (typeFor) {
                    case "USER_MILESTONE":
                        state.copiedMilestoneIdForUser = id;
                        break;
                    case "ORG_MILESTONE":
                        state.copiedMilestoneIdForOrg = id;
                        break;
                    case "USER_ACHIEVEMENT":
                        state.copiedAchievementIdForUser = id;
                        break;
                    case "ORG_ACHIEVEMENT":
                        state.copiedAchievementIdForOrg = id;
                        break;
                }
            });
        }
        function removeSecond(time) {
            var hms = time.split(":");
            if (hms && hms.length > 2) {
                return hms[0] + ":" + hms[1];
            }
            else {
                return time;
            }
        }
        function isKickoffQuestionnaireAnswered() {
            kickoffQuestionnaireInteractor.isQuestionnaireAnswered(state.teamId).then(function (result) {
                state.questionnaire = result.data;
            }).catch(function (error) {
                state.questionnaire = [];
            });
        }
        var projectInfo = computed(function () { return store.state.projectInfo; });
        var isLeader = computed(function () { return store.state.projectContractorStatus === 'LEADER'; });
        var contactorStatusDetected = computed(function () { return store.state.projectContractorStatus !== null; });
        var teamInfo = computed(function () { return store.state.teamInfo; });
        var aiqOrigin = computed(function () { return window.location.origin; });
        var basePathForUser = computed(function () { var _a; return aiqOrigin.value + "/project/" + state.projectId + "/management/" + ((_a = teamInfo.value) === null || _a === void 0 ? void 0 : _a.team_id); });
        var basePathForOrganization = computed(function () { var _a; return aiqOrigin.value + "/project/" + state.projectId + "/contractor/" + ((_a = teamInfo.value) === null || _a === void 0 ? void 0 : _a.team_id); });
        var showMeetingPopup = computed(function () {
            return state.modalType === ModalType.Kickoff ||
                state.modalType === ModalType.Meeting ||
                state.modalType === ModalType.MeetingAdd ||
                state.modalType === ModalType.MeetingLast;
        });
        // 404処理
        watch(function () { return store.state.projectInfo; }, function (data) {
            // プロジェクト公開前・停止は見れない
            if (data.progress_status === 'temp_saved' ||
                data.progress_status === 'stop') {
                router.push(RoutePath.PAGE_404);
            }
        });
        watch(function () { return store.state.projectContractorStatus; }, function (value) {
            // 企業は見れない
            if (CommonService.isOrganization()) {
                router.push(RoutePath.PAGE_404);
                return;
            }
            //　チーム未アサインのユーザーは画面見れない
            if (CommonService.isUser() &&
                value === 'NOT_MEMBER') {
                router.push(RoutePath.PAGE_404);
                return;
            }
        });
        watch(function () { return store.state.organizationMenuInfo; }, function (org) {
            var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
            if (!org) {
                return;
            }
            organizationInteractor
                .getOrganRating(org.account_id, 1, 100)
                .then(function (res) {
                if (!res) {
                    return;
                }
                for (var _i = 0, _a = res.data; _i < _a.length; _i++) {
                    var rate = _a[_i];
                    if (rate.team_id === state.teamId) {
                        state.ratingDate = rate.date;
                    }
                }
            });
        });
        onMounted(function () {
            // マイルストーン一覧取得
            var milestoneInteractor = DIContainer.instance.get(MilestoneInteractor);
            milestoneInteractor.getMilestones(state.teamId).then(function (response) {
                state.milestoneSource = response.data;
                setMilestonesToDisplayable(response.data);
                var deliverableInteractor = DIContainer.instance.get(DeliverableInteractor);
                return deliverableInteractor.getDeliverable(state.projectId, state.teamId);
            }).then(function (deliverableResponse) {
                var _a;
                if (!Array.isArray(deliverableResponse.data) && ((_a = deliverableResponse.data) === null || _a === void 0 ? void 0 : _a.browsed_by_organization)) {
                    state.step = MileStoneStep.confirmed;
                }
                state.achievement = Array.isArray(deliverableResponse.data) ? null : deliverableResponse.data;
                state.preload = false;
                // 直リンクによるモーダル表示
                var milestoneId = route.query.milestone && Number(route.query.milestone);
                var achievementId = route.query.achievement && Number(route.query.achievement);
                if (milestoneId) {
                    if (state.kickOff && state.kickOff.id === milestoneId) {
                        state.selectedMilestone = state.kickOff;
                        state.modalType = ModalType.Kickoff;
                    }
                    var regular = state.regulars.find(function (m) { return m.id === milestoneId; });
                    if (regular) {
                        state.selectedMilestone = regular;
                        state.modalType = ModalType.Meeting;
                    }
                    if (state.review && state.review.id === milestoneId) {
                        state.selectedMilestone = state.review;
                        state.modalType = ModalType.MeetingLast;
                    }
                }
                else if (achievementId && state.achievement && achievementId === state.achievement.id) {
                    state.modalType = ModalType.Achievement;
                }
            }).catch(function (e) {
                console.error(e);
                state.preload = false;
            });
            isKickoffQuestionnaireAnswered();
        });
        return {
            state: state,
            RoutePath: RoutePath,
            ModalType: ModalType,
            MileStoneStep: MileStoneStep,
            showMeetingPopup: showMeetingPopup,
            projectInfo: projectInfo,
            isLeader: isLeader,
            contactorStatusDetected: contactorStatusDetected,
            teamInfo: teamInfo,
            todayStr: todayStr,
            copyUrlToClipboard: copyUrlToClipboard,
            updateMilestone: updateMilestone,
            updateEditMilestone: updateEditMilestone,
            updateLatestMilestone: updateLatestMilestone,
            updateLatestQuestionnaire: updateLatestQuestionnaire,
            isKickoffQuestionnaireAnswered: isKickoffQuestionnaireAnswered,
            updateAchievement: updateAchievement,
            closeEditDifferenceModal: closeEditDifferenceModal,
            updateEditAchievement: updateEditAchievement,
            removeSecond: removeSecond,
        };
    }
});
