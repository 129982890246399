<template>
  <div class="project_detail__container__body">
    <Preloaders v-if="state.preload" />
    <div v-else>
      <div>

        <h4 class="project_detail_management__header">
          プロジェクト管理
        </h4>

        <p class="project_detail_management__description">
          チームリーダーは、プロジェクト期間中、キックオフ/ミーティングの日程が決まり次第、下記に登録してください。<br/>
          また、ミーティング終了後は、3日以内に、参加者・議事録のアップロードをお願い致します。<br/>
          記載した議事録は、共有用のURLを通じて、チーム内/企業に共有することが可能ですので、ご活用ください。<br>
        </p>

        <div v-if="state.preload || !contactorStatusDetected">
          <Preloaders :count="10" />
        </div>
        <table
          v-else
          class="milestone_table"
        >
          <!-- キックオフ -->
          <tr>
            <td class="is-process">
            </td>
            <td class="is-title">
              キックオフ
            </td>
            <td class="is-date">
              <time v-if=state.kickOff>
                {{ state.kickOff?.meeting_start_at.split(" ")[0].replaceAll("-", "/") }}
                <br/>
                {{ removeSecond(state.kickOff?.meeting_start_at.split(" ")[1]) }}〜{{ removeSecond(state.kickOff?.meeting_end_at.split(" ")[1]) }}
              </time>
              <span v-else class="is-empty-text">
                未設定
              </span>
            </td>
            <td class="is-milestone-button">
              <button
                class="btn-sm btn-blue"
                @click="state.selectedMilestone = state.kickOff; state.modalType = ModalType.Kickoff"
              >
                会議情報を入力する
              </button>
            </td>
            <td class="is-url-button">
              <button
                v-if="state.kickOff"
                class="btn-s btn-blue-gray w130"
                @click="copyUrlToClipboard('USER_MILESTONE', state.kickOff?.id)"
              >
                チーム共有用URL
                <i v-if="state.kickOff?.id === state.copiedMilestoneIdForUser" class="fas fa-check fa-xs"></i>
              </button>
            </td>
            <td class="is-url-button">
              <button
                v-if="state.kickOff"
                class="btn-s btn-blue-gray w130"
                @click="copyUrlToClipboard('ORG_MILESTONE', state.kickOff?.id)"
              >
                企業共有用URL
                <i v-if="state.kickOff?.id === state.copiedMilestoneIdForOrg" class="fas fa-check fa-xs"></i>
              </button>
            </td>
          </tr>
          <!-- 会議 -->
          <tr v-for="milestone in state.regulars" :key="milestone.id">
            <td class="is-process">
            </td>
            <td class="is-title">
              会議
            </td>
            <td class="is-date">
              <time v-if="milestone.meeting_start_at">
                {{ milestone.meeting_start_at.split(" ")[0].replaceAll("-", "/") }}
                <br/>
                {{ removeSecond(milestone.meeting_start_at.split(" ")[1]) }}〜{{ removeSecond(milestone.meeting_end_at.split(" ")[1]) }}
              </time>
              <span v-else class="is-empty-text">
                未設定
              </span>
            </td>
            <td class="is-milestone-button">
              <button
                class="btn-sm btn-blue"
                :class="{ disabled: state.step < MileStoneStep.kickoff }"
                :disabled="state.step < MileStoneStep.kickoff"
                @click="state.selectedMilestone = milestone; state.modalType = ModalType.Meeting"
              >
                会議情報を入力する
              </button>
            </td>
            <td class="is-url-button">
              <button
                  class="btn-s btn-blue-gray w130"
                  @click="copyUrlToClipboard('USER_MILESTONE', milestone.id)"
              >
                チーム共有用URL
                <i v-if="milestone.id === state.copiedMilestoneIdForUser" class="fas fa-check fa-xs"></i>
              </button>
            </td>
            <td class="is-url-button">
              <button
                  class="btn-s btn-blue-gray w130"
                  @click="copyUrlToClipboard('ORG_MILESTONE', milestone.id)"
              >
                企業共有用URL
                <i v-if="milestone.id === state.copiedMilestoneIdForOrg" class="fas fa-check fa-xs"></i>
              </button>
            </td>
          </tr>
          <!-- 追加会議 -->
          <tr v-if="isLeader && state.step < MileStoneStep.confirmed">
            <td class="is-process no-point"></td>
            <td class="is-title"></td>
            <td class="is-date"></td>
            <td class="is-milestone-button">
              <button
                class="add-meeting"
                :class="{ disabled: state.step < MileStoneStep.kickoff }"
                :disabled="state.step < MileStoneStep.kickoff"
                @click="state.modalType = ModalType.MeetingAdd"
              >
                ＋ 会議日を追加
              </button>
            </td>
            <td class="is-url-button"></td>
            <td class="is-url-button"></td>
          </tr>
          <!-- 最終報告 -->
          <tr v-if="state.review">
            <td class="is-process">
            </td>
            <td class="is-title">
              最終報告
            </td>
            <td class="is-date">
              <time v-if="state.review?.meeting_start_at">
                {{ state.review?.meeting_start_at.split(" ")[0].replaceAll("-", "/") }}
                <br/>
                {{ removeSecond(state.review?.meeting_start_at.split(" ")[1]) }}〜{{ removeSecond(state.review?.meeting_end_at.split(" ")[1]) }}
              </time>
              <span v-else class="is-empty-text">
                未設定
              </span>
            </td>
            <td class="is-milestone-button">
              <button
                class="btn-sm btn-blue"
                :class="{ disabled: state.step < MileStoneStep.meeting }"
                :disabled="state.step < MileStoneStep.meeting"
                @click="state.selectedMilestone = state.review; state.modalType = ModalType.MeetingLast"
              >
                会議情報を入力する
              </button>
            </td>
            <td class="is-url-button">
              <button
                  v-if="state.review"
                  class="btn-s btn-blue-gray w130"
                  @click="copyUrlToClipboard('USER_MILESTONE', state.review?.id)"
              >
                チーム共有用URL
                <i v-if="state.review?.id === state.copiedMilestoneIdForUser" class="fas fa-check fa-xs"></i>
              </button>
            </td>
            <td class="is-url-button">
              <button
                  v-if="state.review"
                  class="btn-s btn-blue-gray w130"
                  @click="copyUrlToClipboard('ORG_MILESTONE', state.review?.id)"
              >
                企業共有用URL
                <i v-if="state.review?.id === state.copiedMilestoneIdForOrg" class="fas fa-check fa-xs"></i>
              </button>
            </td>
          </tr>
          <!-- 成果提出 -->
          <tr>
            <td class="is-process">
            </td>
            <td class="is-title">
              成果提出
            </td>
            <td class="is-date">
              <time v-if="state.achievement">
                {{ state.achievement?.updated_at.split(" ")[0].replaceAll("-", "/") }}
              </time>
            </td>
            <td class="is-milestone-button">
              <button
                class="btn-sm btn-blue"
                :class="{ disabled: state.step < MileStoneStep.review }"
                :disabled="state.step < MileStoneStep.review"
                @click="state.modalType = ModalType.Achievement"
              >
                {{ state.step < MileStoneStep.confirmed ? '提出する' : '確認済み' }}
              </button>
            </td>
            <td class="is-url-button">
              <button
                  v-if="state.achievement"
                  class="btn-s btn-blue-gray w130"
                  @click="copyUrlToClipboard('USER_ACHIEVEMENT', state.achievement?.id)"
              >
                チーム共有用URL
                <i v-if="state.achievement?.id === state.copiedAchievementIdForUser" class="fas fa-check fa-xs"></i>
              </button>
            </td>
            <td class="is-url-button">
              <button
                  v-if="state.achievement"
                  class="btn-s btn-blue-gray w130"
                  @click="copyUrlToClipboard('ORG_ACHIEVEMENT', state.achievement?.id)"
              >
                企業共有用URL
                <i v-if="state.achievement?.id === state.copiedAchievementIdForOrg" class="fas fa-check fa-xs"></i>
              </button>
            </td>
          </tr>
          <!-- 評価 -->
          <!-- 2022年度1タームは非表示  -->
          <tr v-if="false">
            <td class="is-process">
            </td>
            <td class="is-title">
              評価（任意）
            </td>
            <td class="is-date">
              <time v-if="state.ratingDate">
                {{ state.ratingDate }}
              </time>
            </td>
            <td class="is-milestone-button">
              <button
                v-if="isLeader && !teamInfo.is_rated"
                class="btn-sm btn-blue"
                :class="{ disabled: state.step < MileStoneStep.confirmed || teamInfo.is_rated }"
                :disabled="state.step < MileStoneStep.confirmed || teamInfo.is_rated"
                @click="state.modalType = ModalType.Rating"
              >
                プロジェクトを評価する
              </button>
              <div class="rate_label" v-else-if="teamInfo.is_rated">
                評価済み
              </div>
              <div class="rate_label" v-else>
                評価前
              </div>
            </td>
            <td class="is-url-button"></td>
            <td class="is-url-button"></td>
          </tr>
        </table>

      </div>
    </div>
    <MeetingEditPopup
      v-if="showMeetingPopup"
      :type="state.modalType"
      :milestone="state.selectedMilestone"
      :questionnaire ="state.questionnaire"
      :disableSelectReview="!!state.review && state.selectedMilestone?.meeting_type !== 'REVIEW'"
      @close="state.selectedMilestone = null; state.modalType = null;"
      @complete="updateMilestone"
      @complet_questionnaire ="isKickoffQuestionnaireAnswered"
      @edit_milestone="updateEditMilestone"
      @latest_milestone="updateLatestMilestone"
      @latest_questionnaire="updateLatestQuestionnaire"
    />
    <MeetingEditDifferenceDisplayPopup
      v-if="state.editForm && (state.modalType === ModalType.MeetingEdit || state.modalType === ModalType.KickoffEdit)"
      :type="state.modalType"
      :milestone="state.editForm"
      :latestMilestone="state.latestForm"
      :latestQuestionnaire="state.latestQuestionnaire"
      @close="closeEditDifferenceModal"
      @edit_milestone="updateEditMilestone"
      @latest_milestone="updateLatestMilestone"
      @complete="updateMilestone"
      @complet_questionnaire ="isKickoffQuestionnaireAnswered"
    />
    <AchievementEditPopup
      v-if="state.step < MileStoneStep.confirmed && state.modalType === ModalType.Achievement"
      :achievement="state.achievement"
      @updateEditAchievement="updateEditAchievement"
      @updateLatestAchievement="updateAchievement"
      @close="state.modalType = null"
      @complete="updateAchievement"
    />
    <AchievementEditDifferenceDisplayPopup
      v-if="state.editAchievement && state.modalType === ModalType.AchievementEdit"
      :editAchievement="state.editAchievement"
      :latestAchievement="state.achievement"
      @updateEditAchievement="updateEditAchievement"
      @updateLatestAchievement="updateAchievement"
      @close="state.modalType = null; state.editAchievement = null"
      @complete="updateAchievement"
    />
    <RatingEditPopup
      v-if="isLeader && state.modalType === ModalType.Rating"
      @complete="teamInfo.is_rated = true; state.ratingDate = todayStr()"
      @close="state.modalType = null;"
    />
  </div>
</template>

<script lang="ts" src="./ProjectDetailManagement.ts"></script>
<style lang="scss" src="./ProjectDetailManagement.scss" scoped></style>
